import { ReactElement } from 'react';

import DictionariesListItem from './components/DictionariesListItem';
import { ICustomDictionaryType } from '~/services/api/dictionaries/types';

import styles from '../../Dictionaries.module.scss';

interface IProps {
  dictionaryTypes: ICustomDictionaryType[];
  selectedDictionaryType: string;
  setDictionaryType: (dictionaryType: ICustomDictionaryType) => void;
}

const DictionariesList = ({
  dictionaryTypes,
  selectedDictionaryType,
  setDictionaryType,
}: IProps): ReactElement => (
  <div className={styles.leftColumn}>
    {dictionaryTypes.map(item => (
      <DictionariesListItem
        key={item.type}
        {...item}
        setDictionaryType={setDictionaryType}
        selectedDictionaryType={selectedDictionaryType}
      />
    ))}
  </div>
);

export default DictionariesList;

import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { INetworkActions, IThunkGetAllocatedClients } from '../types';

const onGetAllocatedClients: IThunkGetAllocatedClients = thunk(
  async ({ setAllocatedClients }: Actions<INetworkActions>, payload) => {
    const { data } = await api.client.getAllocatedClients(payload);
    setAllocatedClients(data);
  },
);

export default onGetAllocatedClients;

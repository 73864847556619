import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import isEqual from 'lodash/isEqual';
import { ReactElement, useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';

import Status from '~/ui/components/common/Status';
import AutoSubmit from '~/ui/components/inputs/AutoSubmit';
import Input from '~/ui/components/inputs/Input';
import SelectComponent from '~/ui/components/inputs/SelectWithoutAnimation';

import getButtonText from '~/utils/text/getButtonText';
import useMemoCompare from '~/hooks/useMemoCompare';
import { extractErrorMessage } from '~/utils/error/error';
import { IParams as IValsParams } from '~/services/api/types';
import useRole from '~/store/user/hooks/useRole';
import { useStoreActions, useStoreState } from '~/store/hooks';

import { IUserRole } from '~/types';
import { red } from '~/ui/constants/colors';
import { EDIT_TEAM_MEMBER, VIEW_ACT_TEAM_MEMBER } from '~/ui/constants/paths';
import { multipleStatusesOptions, sortingOptions } from '~/ui/constants/sortingOptions';
import { IActTeamMember, Status as UserStatus } from '~/services/api/actTeamMember/types';
import { IGetActTeamMembersRequestPayload } from '~/store/actTeamMember/types';

import editDisabledIcon from '~/ui/assets/images/editDisabled.svg';
import editIcon from '~/ui/assets/images/editGrey.svg';
import teamLeaderIcon from '~/ui/assets/images/teamLeader.svg';
import { ReactComponent as GlobeIcon } from '~/ui/assets/images/globe.svg';

import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './TeamMembers.module.scss';

interface IProps {
  clinicId: string;
  actTeamId: string;
  noActions?: boolean;
  setTeamMember: (v: IActTeamMember) => void;
}

let prevRequestPayload: IGetActTeamMembersRequestPayload;

const Table = ({ clinicId, actTeamId, noActions, setTeamMember }: IProps): ReactElement => {
  const navigate = useNavigate();

  const filters = useStoreState(state => state.actTeamMember.filters);
  const { id: userId } = useStoreState(state => state.user.current);
  const formMethods = useForm({ defaultValues: filters });

  const { isSuperAdmin, isProductOwner } = useRole();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const watchValues = watch();

  const actTeamMembers = useStoreState(state => state.actTeamMember.list);
  const pagination = useStoreState(state => state.actTeamMember.pagination);

  const showError = useStoreActions(state => state.snackbar.showError);
  const onGetActTeamMembers = useStoreActions(actions => actions.actTeamMember.onGetActTeamMembers);

  const memoizedFilters = useMemoCompare(filters, (prev, next) => prev && isEqual(prev, next));

  const onMount = useCallback(async () => {
    try {
      const requestPayload = {
        clinicId,
        actTeamId,
        params: { pageSize: pagination.pageSize, pageNumber: 1, ...memoizedFilters },
      };

      if (isEqual(prevRequestPayload, requestPayload)) {
        return;
      }

      await onGetActTeamMembers(requestPayload);
      prevRequestPayload = requestPayload;
    } catch (e) {
      showError(extractErrorMessage(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actTeamId, clinicId, onGetActTeamMembers, showError]);

  const onSubmit = async (vals: IValsParams) => {
    const { pageSize, pageNumber } = pagination;
    const payload = { clinicId, actTeamId, params: { pageSize, pageNumber, ...vals } };
    await onGetActTeamMembers(payload);
  };

  useEffect(() => {
    onMount();

    return () => {
      prevRequestPayload = undefined;
    };
  }, [onMount]);

  const handlePagination = (pageNumber: number, pageSize: number) => {
    onGetActTeamMembers({
      clinicId,
      actTeamId,
      params: { pageNumber, pageSize, ...watchValues },
    });
  };

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item sm={4}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search members"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Sort By"
                  control={control}
                  name="sorting"
                  errors={errors}
                  options={sortingOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <Grid item sm={2}>
                <SelectComponent
                  label="Filter By Status"
                  control={control}
                  name="status"
                  errors={errors}
                  options={multipleStatusesOptions}
                  color={red}
                  hideSelectedOptions={false}
                />
              </Grid>
              <AutoSubmit debounce={1000} initialValues={filters} onSubmit={onSubmit} />
            </Grid>
          </form>
        </FormProvider>
      </Box>
      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell width={1} />
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Status</TableCell>
            {!noActions && (
              <>
                <TableCell>Actions</TableCell>
                <TableCell />
                <TableCell />
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {actTeamMembers.map(actTeamMember => {
            const currentClinicRoles = actTeamMember?.roles?.filter(
              role => role.clinic.id === Number(clinicId),
            );
            // check if user team leader in current team
            const isTeamLeader = currentClinicRoles
              ?.find(role => role.name === IUserRole.ActTeamLeader)
              ?.teams?.some(item => item.id === Number(actTeamId));

            // check if user team member in current team
            const isTeamMember = currentClinicRoles
              ?.find(role => role.name === IUserRole.ActTeamMember)
              ?.teams?.some(item => item.id === Number(actTeamId));

            // check if user is global user
            const isGlobalUser = currentClinicRoles?.find(
              role => role.name === IUserRole.GlobalUser,
            );

            const isOnlyGlobalUser = isGlobalUser && !isTeamLeader && !isTeamMember;

            const isCurrentUser = actTeamMember.id === userId;

            return (
              <TableRow
                key={actTeamMember.id}
                style={{
                  borderLeft: isTeamLeader ? `3px solid ${variables.colorRed}` : '',
                }}
              >
                <TableCell width={1}>
                  {isTeamLeader && (
                    <span>
                      <img src={teamLeaderIcon} alt="teamLeader" />
                    </span>
                  )}
                  {isGlobalUser && <GlobeIcon className={styles.globeIcon} />}
                </TableCell>
                <TableCell>
                  <Link
                    className={classNames(styles.link, {
                      [styles.disabled]: isSuperAdmin || isProductOwner,
                    })}
                    to={{
                      pathname: VIEW_ACT_TEAM_MEMBER.replace(':actTeamId', actTeamId).replace(
                        ':teamMemberId',
                        String(actTeamMember.id),
                      ),
                    }}
                  >
                    {actTeamMember.firstName}
                  </Link>
                </TableCell>

                <TableCell>{actTeamMember.lastName}</TableCell>
                <TableCell>{actTeamMember.email}</TableCell>
                <TableCell>
                  <Status status={actTeamMember.status} />
                </TableCell>
                {!noActions && (
                  <TableCell>
                    {['Deactivated', 'Locked'].includes(actTeamMember.status) ? (
                      <IconButton disabled>
                        <img src={editDisabledIcon} alt="disabled" />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() =>
                          navigate(
                            EDIT_TEAM_MEMBER.replace(':actTeamId', actTeamId).replace(
                              ':teamMemberId',
                              String(actTeamMember.id),
                            ),
                          )
                        }
                      >
                        <img src={editIcon} alt="edit" />
                      </IconButton>
                    )}
                  </TableCell>
                )}
                {!noActions && (
                  <TableCell className={styles.column}>
                    <button
                      type="button"
                      className={styles.actionButton}
                      onClick={() => setTeamMember(actTeamMember)}
                      disabled={actTeamMember.status === 'Active' && isCurrentUser}
                    >
                      {getButtonText(actTeamMember.status)}
                    </button>
                    {actTeamMember.status === 'Pending' && (
                      <button
                        type="button"
                        className={styles.actionButton}
                        onClick={() =>
                          setTeamMember({
                            ...actTeamMember,
                            status: 'VerifyingEmail' as UserStatus,
                          })
                        }
                      >
                        Archive
                      </button>
                    )}
                  </TableCell>
                )}
                {!noActions && (
                  <TableCell>
                    <button
                      type="button"
                      disabled={
                        actTeamMember.status !== 'Active' ||
                        (isTeamLeader && !isTeamMember) ||
                        isOnlyGlobalUser // disable if user has only team leader role in current team or inactive
                      }
                      className={styles.actionButton}
                      onClick={() =>
                        setTeamMember({ ...actTeamMember, status: 'Assigned' as UserStatus })
                      }
                    >
                      Unassign
                    </button>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => {
          handlePagination(pageNumber + 1, pagination.pageSize);
        }}
        onRowsPerPageChange={e => {
          handlePagination(1, Number(e.target.value));
        }}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;

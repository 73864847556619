import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import get from 'lodash/get';
import { ReactElement, useRef, useState } from 'react';
import DatePicker, { CalendarContainer, CalendarContainerProps } from 'react-datepicker';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { isValid } from 'date-fns';
import classNames from 'classnames';

import 'react-datepicker/dist/react-datepicker.css';

import { generalDate } from '~/ui/constants/dateFormat';

import { ReactComponent as CalendarIcon } from '~/ui/assets/images/calendarIcon.svg';
import variables from '~/ui/assets/styles/colors.module.scss';
import styles from './DatePickerNew.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  dateFormat?: string;
  size?: FormControlProps['size'];
  className?: string;
  title?: string;
  withConfirmation?: boolean;
  inputDisabled?: boolean;
  inputClassName?: string;
  onSubmit?: (value: Date) => void;
  onCancel?: () => void;
}

const CustomDatePicker = ({
  control,
  errors,
  name,
  minDate,
  maxDate,
  label = '',
  placeholder = '',
  disabled = false,
  dateFormat = generalDate,
  size = 'small',
  className,
  title,
  withConfirmation,
  inputDisabled,
  inputClassName,
  onSubmit,
  onCancel,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');
  const isError = Boolean(error);

  const pickerRef = useRef<DatePicker>(null);

  const [day, setDay] = useState<Date | null>(null);

  const renderCalendarTitle = ({
    className: containerClassName,
    children,
  }: CalendarContainerProps) => (
    <CalendarContainer className={containerClassName}>
      <div className={styles.calendarTitle}>{title}</div>
      <div style={{ position: 'relative' }}>{children}</div>
    </CalendarContainer>
  );

  const handleClose = () => {
    pickerRef.current.setOpen(false);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined" size={size} fullWidth className={styles.datePickerControl}>
          {label && (
            <InputLabel shrink error={isError} className={inputClassName}>
              {label}
            </InputLabel>
          )}
          <DatePicker
            ref={pickerRef}
            onChange={date => {
              if (!withConfirmation) {
                onChange(date);
              } else {
                setDay(date);
              }
            }}
            isClearable={!inputDisabled}
            calendarContainer={title ? renderCalendarTitle : undefined}
            onChangeRaw={event => {
              if (inputDisabled || withConfirmation) {
                return;
              }
              const date = event.target.value;
              const isValidDate = date ? isValid(new Date(date)) : false;
              if (isValidDate) {
                onChange(date, event);
              }
            }}
            onClickOutside={() => {
              setDay(null);
              onCancel();
            }}
            selected={day || value}
            placeholderText={placeholder}
            disabled={disabled}
            shouldCloseOnSelect={false}
            calendarClassName={styles.calendar}
            customInput={
              <OutlinedInput
                disabled={inputDisabled}
                endAdornment={<CalendarIcon color={variables.colorRed} />}
                fullWidth
                className={inputClassName}
              />
            }
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            dateFormat={dateFormat}
            className={className}
          >
            {withConfirmation && (
              <div className={styles.buttonsRow}>
                <div
                  className={classNames(styles.textButton, {
                    [styles.disabled]: !day || day === value,
                  })}
                  onClick={() => {
                    if (day && day !== value) {
                      onSubmit(day);
                    }
                    handleClose();
                  }}
                  aria-hidden
                >
                  OK
                </div>
                |
                <div
                  className={styles.textButton}
                  onClick={() => {
                    onCancel();
                    setDay(null);
                    handleClose();
                  }}
                  aria-hidden
                >
                  CANCEL
                </div>
              </div>
            )}
          </DatePicker>

          {error && (
            <FormHelperText error className={styles.errorText}>
              {error.toString()}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default CustomDatePicker;

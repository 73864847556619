import { IClientAllocationFilters } from '~/store/clientAllocation/types';
import { IClientsFilters } from '~/store/client/types';
import { IFilters } from '../api/types';
import {
  IClientLastContactFilters,
  IClientLengthOfStayFilters,
  IClientStatusFilters,
  IClientTreatmentPlansFilters,
  ICommonReportFilters,
  IDashboardFilters,
  ILocationGroupFilters,
  IMedicationsReportFilters,
  IRecertificationReportFilters,
  ITeamMemberDashboardFilters,
  IToxicologyFilters,
  IVisitStatusReportFilters,
} from '../api/reports/types';
import { getItem, setItem } from '.';
import { IResourcesFilters } from '../api/resource/types';
import { ISosRequestsFilters } from '~/store/sosRequests/types';
import { IVisitsFilters } from '../api/visits/types';
import { ICommonNetworkFilters } from '~/store/network/types';

export const setAllocationsFilter = (data: IClientAllocationFilters): void =>
  setItem('allocations', data);
export const getAllocationsFilter = (): IClientAllocationFilters => getItem('allocations');
const deleteAllocationsFilter = (): void => {
  localStorage.removeItem('allocations');
};

export const setSosRequestsFilter = (data: ISosRequestsFilters): void =>
  setItem('sosRequests', data);
export const getSosRequestsFilter = (): ISosRequestsFilters => getItem('sosRequests');
const deleteSosRequestsFilter = (): void => {
  localStorage.removeItem('sosRequests');
};

export const setTeamMembersFilter = (data: IFilters): void => setItem('teamMembers', data);
export const getTeamMembersFilter = (): IFilters => getItem('teamMembers');
const deleteTeamMembersFilter = (): void => {
  localStorage.removeItem('teamMembers');
};

export const setClientsFilter = (data: IClientsFilters): void => setItem('clients', data);
export const getClientsFilter = (): IClientsFilters => getItem('clients');
const deleteClientsFilter = (): void => {
  localStorage.removeItem('clients');
};

export const setVisitsByFilter = (data: ICommonReportFilters): void =>
  setItem('visitsByFilter', data);
export const getVisitsByFilter = (): ICommonReportFilters => getItem('visitsByFilter');
const deleteVisitsByFilter = (): void => {
  localStorage.removeItem('visitsByFilter');
};

export const setClientLengthOfStayFilter = (data: IClientLengthOfStayFilters): void =>
  setItem('clientLengthOfStayFilter', data);
export const getClientLengthOfStayFilter = (): IClientLengthOfStayFilters =>
  getItem('clientLengthOfStayFilter');
const deleteClientLengthOfStayFilter = (): void => {
  localStorage.removeItem('clientLengthOfStayFilter');
};

export const setLocationGroupFilter = (data: ILocationGroupFilters): void =>
  setItem('locationGroupFilter', data);
export const getLocationGroupFilter = (): ILocationGroupFilters => getItem('locationGroupFilter');
const deleteLocationGroupFilter = (): void => {
  localStorage.removeItem('locationGroupFilter');
};

export const setToxicologyFilter = (data: IToxicologyFilters): void =>
  setItem('toxicologyFilter', data);
export const getToxicologyFilter = (): IToxicologyFilters => getItem('toxicologyFilter');
const deleteToxicologyFilter = (): void => {
  localStorage.removeItem('toxicologyFilter');
};

export const setClientLastContactFilter = (data: IClientLastContactFilters): void =>
  setItem('clientLastContactFilter', data);
export const getClientLastContactFilter = (): IClientLastContactFilters =>
  getItem('clientLastContactFilter');
const deleteClientLastContactFilter = (): void => {
  localStorage.removeItem('clientLastContactFilter');
};

export const setVisitStatusReportFilter = (data: IClientLastContactFilters): void =>
  setItem('visitStatusFilter', data);
export const getVisitStatusReportFilter = (): IVisitStatusReportFilters =>
  getItem('visitStatusFilter');
const deleteVisitStatusReportFilter = (): void => {
  localStorage.removeItem('visitStatusFilter');
};

export const setRecertificationReportFilter = (data: IRecertificationReportFilters): void =>
  setItem('recertificationReportFilter', data);
export const getRecertificationReportFilter = (): IRecertificationReportFilters =>
  getItem('recertificationReportFilter');
const deleteRecertificationReportFilter = (): void => {
  localStorage.removeItem('recertificationReportFilter');
};

export const setDashboardFilter = (data: IDashboardFilters): void =>
  setItem('dashboardFilter', data);
export const getDashboardFilter = (): IDashboardFilters => getItem('dashboardFilter');
const deleteDashboardFilter = (): void => {
  localStorage.removeItem('dashboardFilter');
};

export const setTeamMemberDashboardFilter = (data: ITeamMemberDashboardFilters): void =>
  setItem('teamMemberDashboardFilter', data);
export const getTeamMemberDashboardFilter = (): IDashboardFilters =>
  getItem('teamMemberDashboardFilter');
const deleteTeamMemberDashboardFilter = (): void => {
  localStorage.removeItem('teamMemberDashboardFilter');
};

export const setDemographicsFilter = (data: ICommonReportFilters): void =>
  setItem('demographicsFilter', data);
export const getDemographicsFilter = (): ICommonReportFilters => getItem('demographicsFilter');
const deleteDemographicsFilter = (): void => {
  localStorage.removeItem('demographicsFilter');
};

export const setMedicationsFilter = (data: IMedicationsReportFilters): void =>
  setItem('medicationsFilter', data);
export const getMedicationsFilter = (): ICommonReportFilters => getItem('medicationsFilter');
const deleteMedicationsFilter = (): void => {
  localStorage.removeItem('medicationsFilter');
};

export const setTreatmentPlansFilter = (data: ICommonReportFilters): void =>
  setItem('treatmentPlansFilter', data);
export const getTreatmentPlansFilter = (): ICommonReportFilters => getItem('treatmentPlansFilter');
const deleteTreatmentPlansFilter = (): void => {
  localStorage.removeItem('treatmentPlansFilter');
};

export const setClientStatusFilter = (data: IClientStatusFilters): void =>
  setItem('clientStatusFilter', data);
export const getClientStatusFilter = (): IClientStatusFilters => getItem('clientStatusFilter');
const deleteClientStatusFilter = (): void => {
  localStorage.removeItem('clientStatusFilter');
};

export const setClientTreatmentPlansFilter = (data: IClientTreatmentPlansFilters): void =>
  setItem('clientTreatmentPlansFilter', data);
export const getClientTreatmentPlansFilter = (): IClientTreatmentPlansFilters =>
  getItem('clientTreatmentPlansFilter');
const deleteClientTreatmentPlansFilter = (): void => {
  localStorage.removeItem('clientTreatmentPlansFilter');
};

export const setResourcesFilter = (data: IResourcesFilters): void =>
  setItem('resourcesFilter', data);
export const getResourcesFilter = (): IResourcesFilters => getItem('resourcesFilter');
const deleteResourcesFilter = (): void => {
  localStorage.removeItem('resourcesFilter');
};
export const setVisitsFilter = (data: IVisitsFilters): void => setItem('visitsFilter', data);
export const getVisitsFilter = (): IVisitsFilters => getItem('visitsFilter');
const deleteVisitsFilter = (): void => {
  localStorage.removeItem('visitsFilter');
};

export const setNetworkFilter = (data: ICommonNetworkFilters): void =>
  setItem('networkFilter', data);
export const getNetworkFilter = (): ICommonNetworkFilters => getItem('networkFilter');
const deleteNetworkFilter = (): void => {
  localStorage.removeItem('networkFilter');
};

export const clearAllFilters = (): void => {
  deleteTeamMembersFilter();
  deleteAllocationsFilter();
  deleteSosRequestsFilter();
  deleteClientsFilter();
  deleteTreatmentPlansFilter();
  deleteClientTreatmentPlansFilter();
  deleteNetworkFilter();
  deleteDemographicsFilter();
  deleteDashboardFilter();
  deleteVisitsByFilter();
  deleteResourcesFilter();
  deleteVisitsFilter();
  deleteClientStatusFilter();
  deleteMedicationsFilter();
  deleteClientLengthOfStayFilter();
  deleteLocationGroupFilter();
  deleteResourcesFilter();
  deleteToxicologyFilter();
  deleteClientLastContactFilter();
  deleteRecertificationReportFilter();
  deleteTeamMemberDashboardFilter();
  deleteVisitStatusReportFilter();
};

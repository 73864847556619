import { DictionaryCustomFieldType } from '~/services/api/dictionaries/types';
import DocumentDictionary from './documentDictionary';

const documentTypeDictionary = {
  type: DocumentDictionary.TYPE,
  name: DocumentDictionary.NAME,
  customFields: [
    {
      title: 'Code',
      name: 'code',
    },
    {
      title: 'UI Code',
      name: 'uiCode',
    },
    {
      title: 'Display in Visits',
      name: 'displayInVisits',
      type: DictionaryCustomFieldType.CHECKBOX,
    },
  ],
};

export default documentTypeDictionary;

import React, { ReactElement, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { SendBirdProvider } from 'sendbird-uikit';

import { getSendbirdData } from '~/services/localStorage/auth';
import { useStoreActions, useStoreState } from '~/store/hooks';
import usePasswordExpiration from '~/hooks/auth/usePasswordExpiration';
import useInactivityLogout from '~/hooks/auth/useInactivityLogout';
import { UrlQueryContextProvider } from '../pages/MedicationLogistics/hooks/useQueryParam';
import GeneralLayout from '~/ui/layouts/GeneralLayout';
import ErrorPage from '../pages/ErrorPage';

import { SIGN_IN } from '../constants/paths';

/**
 * Render private route
 * Only authenticated users can access to private route
 * Otherwise - redirect user to another allowed page route
 */

const PrivateRoute = ({ children = null }: { children?: React.ReactNode }): ReactElement => {
  const { authorized, authChecked } = useStoreState(state => state.user);
  const sendBirdUserId = useStoreState(state => state.user.current?.sendBirdUserId);
  const clinicInactivityLogout = useStoreState(
    state => state.clinic.current?.clinicInactivityLogout,
  );

  const { onGetSendbirdData, onLogout } = useStoreActions(actions => actions.user);

  const { accessToken, expiresAt } = getSendbirdData() || {};

  useEffect(() => {
    if (authorized && (!expiresAt || expiresAt < new Date().getTime())) {
      onGetSendbirdData();
    }
  }, [expiresAt, onGetSendbirdData, authorized]);

  usePasswordExpiration();
  useInactivityLogout({ onLogout, skip: !clinicInactivityLogout });

  if (!authChecked) return null;

  return authorized ? (
    <ErrorBoundary fallback={<ErrorPage />}>
      <GeneralLayout>
        <SendBirdProvider
          appId={process.env.REACT_APP_SENDBIRD_ID}
          userId={sendBirdUserId}
          accessToken={accessToken}
        >
          <UrlQueryContextProvider>{children}</UrlQueryContextProvider>
        </SendBirdProvider>
      </GeneralLayout>
    </ErrorBoundary>
  ) : (
    <Navigate to={SIGN_IN} replace />
  );
};

export default PrivateRoute;

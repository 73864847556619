import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MUITable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ICustomDictionaryItem, ICustomDictionaryType } from '~/services/api/dictionaries/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import Loader from '~/ui/components/common/Loader';
import Input from '~/ui/components/inputs/Input';
import TableBodyItem from './components/TableBodyItem';

import styles from '../../Dictionaries.module.scss';

const defaultValues = {
  name: '',
};

interface IProps {
  dictionaryType: ICustomDictionaryType;
  hasCustomFields: boolean;
  clinicId?: number;
  setDictionaryItem: (item: ICustomDictionaryItem | null) => void;
  onEditDictionaryItem: (item: ICustomDictionaryItem) => void;
}

const Table = ({
  dictionaryType,
  hasCustomFields,
  clinicId,
  setDictionaryItem,
  onEditDictionaryItem,
}: IProps): ReactElement => {
  const formMethods = useForm({ defaultValues });
  const [loading, setLoading] = useState(false);

  const currentDictionaryType = useRef<string | null>(null);

  const {
    formState: { errors },
    handleSubmit,
    register,
    watch,
  } = formMethods;

  const list = useStoreState(state => state.dictionaries.list);
  const pagination = useStoreState(state => state.dictionaries.pagination);
  const onGetTypeList = useStoreActions(actions => actions.dictionaries.onGetTypeList);
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const name = watch('name');

  const normalizedPagination = useMemo(
    () => ({
      pageNumber: pagination.pageNumber,
      pageSize: pagination.pageSize,
    }),
    [pagination.pageNumber, pagination.pageSize],
  );

  // performing  search
  const onSubmit = (val: { name: string }) => {
    onGetTypeList({
      type: dictionaryType.type,
      params: { ...val, ...normalizedPagination },
      clinicId,
    });
  };

  // working with pagination
  const handlePagination = (pageSize: number, pageNumber: number) => {
    onGetTypeList({ type: dictionaryType.type, params: { name, pageSize, pageNumber }, clinicId });
  };

  // loading table content
  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await onGetTypeList({
        type: dictionaryType.type,
        params: {
          name,
          ...normalizedPagination,
          ...(currentDictionaryType.current !== dictionaryType.type ? { pageNumber: 1 } : {}), // reset page number if dictionary type has changed
        },
        clinicId,
      });
      currentDictionaryType.current = dictionaryType.type;
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [dictionaryType.type, name, clinicId, normalizedPagination, onGetTypeList, showError]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const headerCustomFields = useMemo(() => {
    if (hasCustomFields)
      return dictionaryType.customFields.map(({ title }) => (
        <TableCell key={title}>{title}</TableCell>
      ));
    return null;
  }, [dictionaryType.customFields, hasCustomFields]);

  return (
    <TableContainer>
      <Box sx={{ p: 2 }}>
        {loading ? <Loader className={styles.loader} /> : null}
        <Grid container spacing={1} className={styles.tableHeader}>
          <Grid item sm={4}>
            <h3>{dictionaryType?.name}</h3>
          </Grid>
          <Grid item sm={8}>
            <FormProvider {...formMethods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Input
                  startAdornment={<SearchIcon color="primary" />}
                  placeholder="Search"
                  register={register}
                  name="name"
                  errors={errors}
                />
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Box>

      <MUITable>
        <TableHead>
          <TableRow>
            <TableCell>{dictionaryType?.name}</TableCell>
            {hasCustomFields && headerCustomFields}
            <TableCell>Status</TableCell>
            <TableCell className={styles.headerCell}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(item => (
            <TableBodyItem
              key={item.id}
              dictionaryItem={item}
              setDictionaryItem={setDictionaryItem}
              dictionaryType={dictionaryType}
              hasCustomFields={hasCustomFields}
              onEditDictionaryItem={onEditDictionaryItem}
            />
          ))}
        </TableBody>
      </MUITable>
      <TablePagination
        component="div"
        count={pagination.total}
        onPageChange={(_, pageNumber) => handlePagination(pagination.pageSize, pageNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pagination.pageNumber - 1}
        rowsPerPage={pagination.pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default Table;

import { action } from 'easy-peasy';

import { IActionUpdateTeamMemberLocation } from '../types';

const updateTeamMemberLocation: IActionUpdateTeamMemberLocation = action((state, payload) => {
  const isUserExist = state.teamMembersLocation.items.find(
    item => item.user.id === payload.user.id,
  );

  state.teamMembersLocation.items = isUserExist
    ? state.teamMembersLocation.items.map(item =>
        item.user.id === payload.user.id ? payload : item,
      )
    : [...state.teamMembersLocation.items, payload];
});

export default updateTeamMemberLocation;

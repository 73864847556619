import { action } from 'easy-peasy';
import isNumber from 'lodash/isNumber';
import { IActionSetClientsListPagination } from '../types';

const setClientsListPagination: IActionSetClientsListPagination = action((state, pagination) => {
  if (isNumber(pagination.pageNumber)) {
    state.pagination.pageNumber = pagination.pageNumber;
  }

  if (isNumber(pagination.pageSize)) {
    state.pagination.pageSize = pagination.pageSize;
  }

  if (isNumber(pagination.total)) {
    state.pagination.total = pagination.total;
  }
});

export default setClientsListPagination;

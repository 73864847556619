import { action } from 'easy-peasy';

import { IActionRemoveTeamMemberLocation } from '../types';

const removeTeamMemberLocation: IActionRemoveTeamMemberLocation = action((state, payload) => {
  state.teamMembersLocation.items = state.teamMembersLocation.items.filter(
    item => item.user.id !== payload,
  );
});

export default removeTeamMemberLocation;

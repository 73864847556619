import Box from '@material-ui/core/Box';
import { FC } from 'react';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form';

import DatePicker from '~/ui/components/inputs/DatePicker';
import RadioGroup from '~/ui/components/inputs/RadioGroup';

import isStatusDone from '../../utils/isStatusDone';

import { IBloodDrawn } from '../AddVisit/types';
import { testingStatusOptions } from '../../constants/toxicologyOptions';

import styles from '../Form/Form.module.scss';

interface IBloodDrawnProps {
  bloodDrawn: IBloodDrawn | null;
  control: any;
  errors: FieldErrors;
}

const BloodDrawn: FC<IBloodDrawnProps> = ({ bloodDrawn, control, errors }) => (
  <Box className={styles.column}>
    <RadioGroup
      control={control}
      name="bloodDrawn.status"
      errors={errors}
      options={testingStatusOptions}
      label="Blood Drawn"
      size="small"
      className={styles.rowRadioGroup}
      unselectable
    />
    {isStatusDone(bloodDrawn?.status) && (
      <Box className={classNames(styles.rowRadioGroup, styles.selectWidth)}>
        <DatePicker
          control={control}
          errors={errors}
          name="bloodDrawn.drawDate"
          openTo="date"
          label="Draw Date"
          maxDate={new Date().toISOString()}
        />
      </Box>
    )}
  </Box>
);

export default BloodDrawn;

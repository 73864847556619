import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloseIcon from '@material-ui/icons/Close';
import { ReactElement, memo } from 'react';

import styles from './AlternativeValues.module.scss';

export interface IAlternativeItem {
  [key: string]: string;
}

interface IProps {
  items: IAlternativeItem;
  setItems: (value: IAlternativeItem) => void;
}

const AlternativeValues = ({ items = {}, setItems }: IProps): ReactElement => {
  const addItem = () => {
    const lastItemKey = Object.keys(items)[Object.keys(items).length - 1];
    const lastItemTextIndex = lastItemKey?.split('value')?.[1];
    const nextItemIndex = lastItemTextIndex ? parseInt(lastItemTextIndex, 10) + 1 : 0;
    setItems({ ...items, [`value${nextItemIndex}`]: '' });
  };

  const onChangeItem = (value: string, key: string) => {
    const newItems = { ...items };
    newItems[key] = value;
    setItems(newItems);
  };

  const removeItem = (key: string) => {
    const newItems = { ...items };
    delete newItems[key];
    setItems(newItems);
  };

  const renderItem = (key: string, index: number) => {
    const item = items[key];
    return (
      <Box key={`${item}-${index}`} className={styles.item}>
        <OutlinedInput
          defaultValue={item}
          onBlur={event => {
            onChangeItem(event.target.value, key);
          }}
          fullWidth
          placeholder={`Value ${index + 1}`}
          endAdornment={
            <IconButton onClick={() => removeItem(key)} size="small">
              <CloseIcon color="primary" />
            </IconButton>
          }
        />
      </Box>
    );
  };

  return (
    <div role="presentation" className={styles.container}>
      <Box className={styles.alternativeValuesHeader}>
        <span>{`Alternative Values (${Object.keys(items)?.length})`}</span>
        <Button variant="text" onClick={addItem} color="primary">
          + Add Value
        </Button>
      </Box>
      <Box className={styles.items}>
        {Object.keys(items).map((key, index) => renderItem(key, index))}
      </Box>
    </div>
  );
};

export default memo(AlternativeValues);

import { action } from 'easy-peasy';

import { IActionResetNetworkStore } from '../types';
import initStore from '../initStore';

const resetNetworkStore: IActionResetNetworkStore = action(state => {
  state.filters = {
    clientIds: [],
    teamIds: [],
    showList: false,
    name: '',
    date: undefined,
  };
  state.selectedMarkerId = initStore.selectedMarkerId;
  state.teamMembersLocation = initStore.teamMembersLocation;
  state.allocatedClients = initStore.allocatedClients;
});

export default resetNetworkStore;

import { action } from 'easy-peasy';
import { setNetworkFilter } from '~/services/localStorage/filters';

import { IActionSetNetworkFilters } from '../types';

const setNetworkFilters: IActionSetNetworkFilters = action((state, payload) => {
  const newFilters = { ...state.filters, ...payload };
  // save filters to storage
  setNetworkFilter(newFilters);

  state.filters = newFilters;
});

export default setNetworkFilters;

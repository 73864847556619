import { Fragment, ReactElement, useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useNavigate, useParams } from 'react-router-dom';
import Done from '@material-ui/icons/Done';

import { useStoreState, useStoreActions } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { destructHours, isoToLocal } from '~/utils/date/date';
import splitSeconds from '../../utils/splitSeconds';
import getTypeString from '../../utils/getTypeString';
import useFeature from '~/store/clinic/hooks/useFeature';
import { extractErrorMessage } from '~/utils/error/error';
import extractToxicologyString from '../../utils/extractToxicologyString';
import extractBloodDrawString from '../../utils/extractBloodDrawString';
import formatClientAddress from '~/utils/dataFormat/formatClientAddress';

import PreviewBreadcrumbs from './components/PreviewBreadcrumbs';
import Loader from '~/ui/components/common/Loader';
import ListItem from '~/ui/components/common/ListItem';

import { IAddressExtended } from '~/services/api/types';
import { visitStatusLabels } from '~/ui/pages/Reports/constants/visitStatusOptions';
import { generalTime2 } from '~/ui/constants/dateFormat';
import { EDIT_VISIT } from '~/ui/constants/paths';

import styles from './PreviewVisit.module.scss';

const PreviewVisit = (): ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { visitId } = useParams<{ visitId: string }>();

  const { current } = useStoreState(state => state.user);
  const { current: client } = useStoreState(state => state.client);
  const { current: visit } = useStoreState(state => state.visits);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const { onGetVisit } = useStoreActions(action => action.visits);
  const { onGetClient } = useStoreActions(action => action.client);
  const { showError } = useStoreActions(action => action.snackbar);

  const query = useQuery();
  const clientId = query.get('clientId');
  const teamId = query.get('teamId');

  const redirectToEditPage = (): void => {
    navigate(
      EDIT_VISIT.replace(':visitId', String(visitId)).concat(
        `?teamId=${teamId}&clientId=${clientId}`,
      ),
      { state: { fromPreview: true } },
    );
  };

  const generateVisitedPersons = (): string => {
    let visitedPersons = '';
    if (!visit) {
      return visitedPersons;
    }
    if (visit?.isClientVisited) {
      visitedPersons = visitedPersons.concat('Client');
    }
    if (visit.isHospitalStaffVisited) {
      visitedPersons = visitedPersons.concat(`${visit.isClientVisited ? ',' : ''} Hospital Staff`);
    }
    if (visit.visitedPeople) {
      visit.visitedPeople.forEach(person => {
        visitedPersons = visitedPersons.concat(
          `${visit.isClientVisited || visit.isHospitalStaffVisited ? ',' : ''} ${person.name} | ${
            person.relationship
          }`,
        );
      });
    }
    return visitedPersons;
  };
  const generateVisitors = (): string => {
    let visitors = '';
    if (!visit) {
      return visitors;
    }
    if (visit.visitedBy) {
      visit.visitedBy.forEach((person, index: number) => {
        visitors = visitors.concat(
          `${person.name}${visit.visitedBy.length - 1 === index ? '' : ', '}`,
        );
      });
    }
    return visitors;
  };

  const onMount = useCallback(async () => {
    try {
      setLoading(true);
      await onGetMyClinic();
      await onGetClient({ clinicId: String(current?.clinic?.id), teamId, clientId });
      await onGetVisit({
        requestInfo: {
          clinicId: String(current?.clinic?.id),
          clientId,
          visitId: String(visitId),
          teamId,
        },
      });
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    clientId,
    current?.clinic?.id,
    onGetClient,
    onGetMyClinic,
    onGetVisit,
    showError,
    teamId,
    visitId,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const { isTreatmentPlansAvailable, isBidirectionalAvailable } = useFeature();

  const editedBy =
    visit?.editInformation &&
    `Edited By ${visit?.editInformation.name}, ${format(
      new Date(visit?.editInformation.date),
      'MMM dd',
    )} at ${format(new Date(visit?.editInformation.date), generalTime2)}`;

  const address = visit?.address && formatClientAddress(visit?.address as IAddressExtended);
  const clientNoShowText = visit?.clientNoShow && (
    <div>
      Client No Show <div className={styles.noShowReason}>{`${visit?.noShowReason.name}`}</div>
    </div>
  );
  if (loading) return <Loader />;
  return (
    <div>
      <Grid container spacing={2} className={styles.headerContainer}>
        <Grid item sm={10}>
          <h2>
            {client?.firstName} {client?.lastName}
          </h2>
          <PreviewBreadcrumbs
            teamId={teamId}
            clientId={clientId}
            visitId={visitId}
            visitDate={visit?.date}
          />
          <Box className={styles.editedBy} component="p">
            {editedBy}
          </Box>
        </Grid>
        <Grid item sm={2} className={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              redirectToEditPage();
            }}
            disabled={visit.isArchived || visit.client.isArchived || !!visit.documentId}
          >
            Edit Visit
          </Button>
        </Grid>
      </Grid>

      <Box className={styles.previewContainer}>
        <ListItem
          label="Visit Info"
          content={clientNoShowText}
          moreLabelWidth
          className={styles.noShowField}
          noActions
        />
        {visit?.date && (
          <ListItem label="Date of Visit" content={isoToLocal(visit?.date)} moreLabelWidth />
        )}
        {visit?.startTime && (
          <ListItem
            label="Time of Visit"
            content={`${format(destructHours(visit.startTime), generalTime2)} ${
              !visit.clientNoShow ? `(${splitSeconds(visit.durationSeconds).trim()})` : ''
            }`}
            moreLabelWidth
          />
        )}
        {!visit?.clientNoShow && (
          <ListItem label="Type of Visit" content={getTypeString(visit?.type)} moreLabelWidth />
        )}
        {visit?.status && isBidirectionalAvailable && (
          <ListItem label="Status" content={visitStatusLabels[visit.status]} moreLabelWidth />
        )}
        {!visit?.clientNoShow && (
          <ListItem label="Visited Person" content={generateVisitedPersons()} moreLabelWidth />
        )}
        <ListItem label="Visited By" content={generateVisitors()} moreLabelWidth />
        {!visit?.clientNoShow && visit?.address && (
          <ListItem label="Address of Visit" content={address} moreLabelWidth />
        )}
        {!visit?.clientNoShow && <ListItem label="Note" content={visit?.note} moreLabelWidth />}
        {!visit?.clientNoShow && visit?.toxicologyResults?.toxicology && (
          <ListItem
            label="Toxicology"
            content={extractToxicologyString(visit?.toxicologyResults?.toxicology)}
            moreLabelWidth
          />
        )}
        {!visit?.clientNoShow && visit?.toxicologyResults?.bloodDrawn && (
          <ListItem
            label="Blood Draw"
            content={extractBloodDrawString(visit?.toxicologyResults?.bloodDrawn)}
            moreLabelWidth
          />
        )}
      </Box>

      {isTreatmentPlansAvailable && (
        <>
          {!!visit?.treatmentPlanDetails && (
            <Box className={styles.previewContainer}>
              <ListItem label="Treatment Plan Info" moreLabelWidth noActions />
              {!!visit.treatmentPlanDetails.goals &&
                visit.treatmentPlanDetails.goals.map((goal, index) => (
                  <Fragment key={`goal-${goal.id}`}>
                    <ListItem
                      label={`Reviewed Goal ${index + 1}`}
                      content={goal.text}
                      moreLabelWidth
                    />
                    <ListItem
                      label="Reviewed Objectives"
                      className={styles.objectiveLabel}
                      content={goal.objectives
                        .filter(objective => objective.reviewed)
                        .map((objective, i) => (
                          <Fragment key={`objective-${objective.id}`}>
                            <div>
                              <span className={styles.objectiveTitle}>{`Objective ${
                                i + 1
                              }. `}</span>
                              <span>{objective.text}</span>
                            </div>
                            <br />
                          </Fragment>
                        ))}
                      moreLabelWidth
                    />
                  </Fragment>
                ))}
            </Box>
          )}
        </>
      )}
      {visit?.billable && (
        <Box className={styles.previewContainer}>
          <ListItem
            label="The visit is billable"
            moreLabelWidth
            noActions
            content={<Done className={styles.green} />}
          />
          {!!visit.interventions?.length && visit.billable && (
            <ListItem
              className={styles.objectiveLabel}
              label="Interventions"
              content={visit.interventions.map(item => (
                <p key={`intervention-${item.id}`}>{item.name}</p>
              ))}
              moreLabelWidth
            />
          )}
        </Box>
      )}
    </div>
  );
};

export default PreviewVisit;

export const userPromptDescription =
  'A user prompt is the input provided by the end-user during their interaction with an AI system. It typically consists of a question, command, or statement that the user wants the AI to respond to.';
export const systemPromptDescription =
  'A system prompt in OpenAI is a special type of message used to guide the behavior of AI models. It provides context, instructions, or specific guidelines to shape how the AI responds.\nFor example, a system prompt might instruct the AI to respond in a friendly and supportive manner, or to focus on providing concise technical information.';
export const testAiResponseDescription =
  'AI Response based on provided parameters: System Prompt, Temperature, Max Tokens and User Prompt.';
export const systemPromptPlaceholder =
  'You are a mental health professional tasked with enhancing visit notes. Expand and enhance the given visit note, adding medical and professional language where appropriate. Be sure to include any key observations, recommendations for treatment adherence, and relevant mental health terminology.';
export const userPromptPlaceholder =
  'You are a mental health professional tasked with enhancing visit notes. Expand and enhance the given visit note, adding medical and professional language where appropriate. Be sure to include any key observations, recommendations for treatment adherence, and relevant mental health terminology.';
export const temperatureDescription = `
Temperature controls the creativity and randomness of the responses generated by the model. It determines how "deterministic" or "divergent" the output is. Temperature is a value between 0 and 1.
\n
Low temperature (e.g., 0 to 0.3): The output will be more focused and deterministic. The model is more likely to give conservative, safe, and predictable responses.\n
High temperature (e.g., 0.7 to 1): The model will generate more creative, varied, and less predictable responses. It can take more risks and generate unexpected content.

`;
export const tokensDescription = `
Tokens represent chunks of text that OpenAI models process. They can be whole words, partial words, or punctuation marks.
\n
Here's how tokens work:
\n
A word like "chat" counts as 1 token.
A longer word like "understanding" may be broken down into multiple tokens: "under" and "standing."
Spaces and punctuation marks also count as tokens.
When making requests to the API, the total number of tokens includes both the input and the output.\nFor example, if your input is 50 tokens and you request 250 tokens for the response, that sums to 300 tokens. Most models have a maximum token limit, like 4096 tokens for GPT-4's omni model.
\n
Token Example:
\n
User input: "Client denies hallucinations and ideations." (6 tokens)
\n
AI output: "The client denies any form of hallucination or ideation and remains stable." (18 tokens)
\n
The higher the MaxTokens you set, the longer the potential response can be.`;

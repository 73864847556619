import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAvailableDictionary, IDictionaryTypes } from '~/services/api/dictionaries/types';
import { IAvailableDictionaryFilters } from '~/store/dictionaries/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

const PAGE_SIZE = 50;

interface IUseMedicationOptions {
  loading: boolean;
  selectedIds: number[];
  items: IAvailableDictionary[];
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  loadMore?: () => void;
  refresh: () => void;
}

let prevRequestPayload: {
  type: string;
} & IAvailableDictionaryFilters;

const useMedicationOptions = (): IUseMedicationOptions => {
  const [loading, setLoading] = useState(false);

  const medicationDictionary = useStoreState(
    store => store.dictionaries.availableDictionaries[IDictionaryTypes.Medication],
  );

  const { list, total = 0, name, current } = medicationDictionary || {};

  const currentPageNumber = medicationDictionary?.pageNumber || 1;

  const currentMedication: IAvailableDictionary[] = useMemo(
    () =>
      current?.length
        ? [
            {
              id: current[0].id,
              value: current[0].value,
              icd10: '',
            },
          ]
        : [],
    [current],
  );

  const selectedIds = useMemo(() => current?.map(item => item.id), [current]);

  const { onGetDictionariesByTypePaginated, setAvailableDictionaryFilters } = useStoreActions(
    action => action.dictionaries,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const itemsLength = list?.length;

  const needLoadMore = itemsLength < total;
  const itemsOffset = Math.ceil(itemsLength / PAGE_SIZE);
  const pageNumber = itemsOffset ? Math.max(itemsOffset, currentPageNumber) : currentPageNumber;

  const fetchMedicationOptions = useCallback(async () => {
    if (!loading) {
      try {
        setLoading(true);

        const requestPayload = {
          type: IDictionaryTypes.Medication,
          name,
          pageSize: name?.length ? PAGE_SIZE * 5 : PAGE_SIZE,
          pageNumber: name?.length ? 1 : pageNumber,
        };

        if (isEqual(prevRequestPayload, requestPayload)) {
          setLoading(false);
          return;
        }

        await onGetDictionariesByTypePaginated(requestPayload);
        prevRequestPayload = requestPayload;
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGetDictionariesByTypePaginated, name, pageNumber, showError]);

  const setSearchQuery = useCallback(
    (value: string) => {
      setAvailableDictionaryFilters({
        type: IDictionaryTypes.Medication,
        name: value,
        pageNumber: 1,
      });
    },
    [setAvailableDictionaryFilters],
  );

  const loadMore = () => {
    if (needLoadMore) {
      setAvailableDictionaryFilters({
        type: IDictionaryTypes.Medication,
        pageNumber: pageNumber + 1,
      });
    }
  };

  const refresh = useCallback(() => {
    setAvailableDictionaryFilters({ type: IDictionaryTypes.Medication, pageNumber: 1 });
  }, [setAvailableDictionaryFilters]);

  useEffect(() => {
    fetchMedicationOptions();
  }, [fetchMedicationOptions]);

  return {
    loading,
    selectedIds,
    items: [...currentMedication, ...(list || [])],
    searchQuery: name || '',
    setSearchQuery,
    loadMore: needLoadMore ? loadMore : undefined,
    refresh,
  };
};

export default useMedicationOptions;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';
import {
  getAccessToken,
  getRefreshToken,
  setPasswordExpiration,
  setTokens,
} from '~/services/localStorage/auth';
import { clearAllFilters } from '~/services/localStorage/filters';

import { IClientActions } from '~/store/client/types';
import { IReportsActions } from '~/store/reports/types';
import { ICoordinateActions } from '~/store/coordinate/types';
import { INetworkActions } from '~/store/network/types';
import { IThunkChangeRole, IUserActions } from '../types';
import { IActTeamActions } from '~/store/actTeam/types';

const onChangeRole: IThunkChangeRole = thunk(
  async (
    {
      setUser,
      setRole,
      resetReportsStore,
      resetClientStore,
      resetCoordinateStore,
      resetNetworkStore,
      resetActTeamStore,
    }: Actions<
      IUserActions &
        Pick<IReportsActions, 'setDashboardReport' | 'resetReportsStore'> &
        Pick<IClientActions, 'resetClientStore'> &
        Pick<ICoordinateActions, 'resetCoordinateStore'> &
        Pick<IActTeamActions, 'resetActTeamStore'> &
        Pick<INetworkActions, 'resetNetworkStore'>
    >,
    payload,
  ) => {
    const currentAccessToken = getAccessToken();
    const currentRefreshToken = getRefreshToken();

    const {
      accessToken,
      refreshToken,
      teamId,
      roleId,
      clinicId,
      passwordExpiresSoon,
      daysTillExpiration,
    } = await api.auth
      .refreshToken({
        ...payload,
        accessToken: currentAccessToken,
        refreshToken: currentRefreshToken,
      })
      .then(response => response.data);

    clearAllFilters();
    resetReportsStore();
    resetClientStore();
    resetCoordinateStore();
    resetNetworkStore();
    resetActTeamStore();

    setTokens({ accessToken, refreshToken });
    setPasswordExpiration({ passwordExpiresSoon, daysTillExpiration });
    const user = await api.auth.getCurrentUser().then(response => response.data);
    setUser(user);
    setRole({ teamId, roleId, clinicId });
  },
);

export default onChangeRole;

import unionBy from 'lodash/unionBy';
import { IClientOption } from '~/store/client/types';
import { IOption } from '~/types';
import { sortByBothNames } from '~/ui/pages/Reports/helpers/sortByName';

export default (clients: IClientOption[]): IOption[] => {
  const sortedClients = (clients || [])
    .sort((a, b) => sortByBothNames(a, b, false))
    .map(item => ({ value: item.id, label: item.name }));

  return unionBy(sortedClients, 'value');
};

import { ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  size?: FormControlProps['size'];
  name: string;
  label?: string;
  disabled?: boolean;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  cb?: () => void;
}

const CheckboxComponent = ({
  control,
  errors,
  size = 'medium',
  name,
  label = '',
  disabled = false,
  labelPlacement,
  cb,
}: IProps): ReactElement => {
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl variant="outlined">
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                color="primary"
                size={size}
                onChange={e => {
                  onChange(e);
                  cb?.();
                }}
              />
            }
            label={label}
            disabled={disabled}
            labelPlacement={labelPlacement}
          />
          {error && <FormHelperText error>{error.toString()}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default CheckboxComponent;

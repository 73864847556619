import { ReactElement, useCallback, useMemo, useState } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import uniq from 'lodash/uniq';

import Modal from '~/ui/components/common/Modal';
import Button from '~/ui/components/common/Button';
import AlternativeValues, { IAlternativeItem } from './AlternativeValues/AlternativeValues';

import {
  DictionaryCustomFieldType,
  ICustomDictionaryItem,
  ICustomDictionaryType,
  IDictionaryTypeCustomField,
} from '~/services/api/dictionaries/types';
import styles from '../Dictionaries.module.scss';

interface IProps {
  hasCustomFields: boolean;
  hasAlternativeValues: boolean;
  dictionaryType: ICustomDictionaryType;
  dictionaryItem: ICustomDictionaryItem;
  handleDictionaryItemUpdateOrAdd: (item: ICustomDictionaryItem) => void;
  onClose: () => void;
  actionModalText: string;
}

const ModalForm = ({
  hasCustomFields,
  hasAlternativeValues,
  dictionaryType,
  dictionaryItem,
  handleDictionaryItemUpdateOrAdd,
  onClose,
  actionModalText,
}: IProps): ReactElement => {
  const [newDictionaryItem, setNewDictionaryItem] = useState<ICustomDictionaryItem>(
    dictionaryItem || ({} as ICustomDictionaryItem),
  );

  const defaultValues = useMemo(
    () =>
      dictionaryItem.alternativeValues?.reduce(
        (values: IAlternativeItem, item: string, index: number) => {
          values[`value${index + 1}`] = item;
          return values;
        },
        {},
      ),
    [dictionaryItem.alternativeValues],
  );

  const [alternativeItems, setAlternativeItems] = useState<IAlternativeItem>(defaultValues);

  const modalDesc = `Please type a meaning${
    hasCustomFields && dictionaryItem?.abbreviation ? ' and an abbreviation' : ''
  } to define the “${dictionaryType.name}” dictionary`;

  const setItems = useCallback(
    (value: { [x: string]: string }) => {
      const newValue = uniq(Object.values(value).filter(item => item.length));
      setNewDictionaryItem({
        ...newDictionaryItem,
        alternativeValues: newValue,
      });
      setAlternativeItems(value);
    },
    [newDictionaryItem],
  );

  const renderCustomField = ({ title, name: n, type }: IDictionaryTypeCustomField) => {
    if (type === DictionaryCustomFieldType.CHECKBOX) {
      const value = newDictionaryItem[n as unknown as keyof typeof newDictionaryItem] as boolean;

      if (typeof value === 'undefined') {
        setNewDictionaryItem({ ...newDictionaryItem, [n]: false });
      }

      return (
        <FormControl variant="outlined" fullWidth margin="normal" key={title}>
          <FormControlLabel
            control={
              <Checkbox
                checked={value || false}
                color="primary"
                onChange={e => {
                  setNewDictionaryItem({ ...newDictionaryItem, [n]: e.target.checked });
                }}
              />
            }
            label={title}
          />
        </FormControl>
      );
    }

    return (
      <FormControl variant="outlined" fullWidth margin="normal" key={title}>
        <InputLabel shrink>Dictionary {title}</InputLabel>
        <OutlinedInput
          value={newDictionaryItem[n as unknown as keyof typeof newDictionaryItem] || ''}
          onChange={({ target }) => {
            const temp = newDictionaryItem;
            temp[n as unknown as keyof typeof newDictionaryItem] = target.value;
            setNewDictionaryItem({ ...temp });
          }}
        />
      </FormControl>
    );
  };

  return (
    <Modal open maxWidth="lg" onClose={onClose}>
      <Box className={styles.innerContainer}>
        <p className={styles.description}>{modalDesc}</p>
        <FormControl variant="outlined" fullWidth>
          <InputLabel shrink>Dictionary Name</InputLabel>
          <OutlinedInput
            value={newDictionaryItem.value || ''}
            onChange={event => {
              setNewDictionaryItem({ ...newDictionaryItem, value: event.target.value });
            }}
          />
        </FormControl>
        {hasCustomFields && dictionaryType.customFields.map(renderCustomField)}
        {hasAlternativeValues && <AlternativeValues items={alternativeItems} setItems={setItems} />}
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonMargin}>
            <Button color="primary" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              handleDictionaryItemUpdateOrAdd(newDictionaryItem);
            }}
          >
            {actionModalText}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ModalForm;

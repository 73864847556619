import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { IClientOption, IClientOptionsRequestPayload } from '~/store/client/types';
import { useStoreActions, useStoreState } from '~/store/hooks';
import useQuery from '~/store/medicationLogistics/hooks/useQuery';
import { extractErrorMessage } from '~/utils/error/error';
import extractFullName from '~/utils/text/extractFullName';
import useMemoCompare from '~/hooks/useMemoCompare';

const PAGE_SIZE = 64;

interface IUseClientOptions {
  loading: boolean;
  items: IClientOption[];
  searchQuery: string;
  teamIds: number[];
  setSearchQuery: (value: string) => void;
  setClientOptionsTeamIds: (value: number[]) => void;
  setCurrentClientOption: (value: IClientOption[]) => void;
  loadMore?: () => void;
  refresh: () => void;
}

let prevRequestPayload: IClientOptionsRequestPayload;

const useClientOptions = (withCurrent = false, includeDeleted = false): IUseClientOptions => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const teamIdQuery = query.get('actTeamId') || query.get('teamId');

  const { list: items, filters, total } = useStoreState(store => store.client.clientOptions);
  const { current } = useStoreState(state => state.user);
  const client = useStoreState(state => state.client.current);

  const { onGetClientOptions, setClientOptionsFilter, setCurrentClientOption } = useStoreActions(
    action => action.client,
  );
  const showError = useStoreActions(actions => actions.snackbar.showError);

  const currentClient: IClientOption[] = useMemo(
    () =>
      client && withCurrent
        ? [
            {
              id: client.id,
              name: extractFullName(client),
              photo: client.photo,
              team: client.actTeam,
            },
          ]
        : [],
    [client, withCurrent],
  );

  const { pageNumber, name } = filters;
  const itemsLength = items?.length;

  const needLoadMore = itemsLength < total;

  const teamId = Number(teamIdQuery) || client?.actTeam?.id || current?.teamId;
  const roleId = current?.roleId;
  const defaultTeamId = teamId ? [teamId] : [];
  const teamIds = filters.teamIds?.length ? filters.teamIds : defaultTeamId;

  const memoizedTeamIds = useMemoCompare(teamIds, (prev, next) => prev && isEqual(prev, next));

  useEffect(() => {
    setCurrentClientOption([]);
  }, [memoizedTeamIds, setCurrentClientOption]);

  const fetchClientOptions = useCallback(async () => {
    if (!loading && current?.clinic?.id && memoizedTeamIds?.length) {
      try {
        setLoading(true);

        const requestPayload = {
          clinicId: String(current.clinic.id),
          name,
          pageSize: name?.length ? PAGE_SIZE * 5 : PAGE_SIZE,
          pageNumber: name?.length ? 1 : pageNumber,
          teamIds: memoizedTeamIds,
          roleId,
          includeDeleted,
        };

        if (isEqual(prevRequestPayload, requestPayload)) {
          setLoading(false);
          return;
        }

        await onGetClientOptions(requestPayload);
        prevRequestPayload = requestPayload;
      } catch (e) {
        showError(extractErrorMessage(e));
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    current?.clinic?.id,
    roleId,
    onGetClientOptions,
    name,
    pageNumber,
    memoizedTeamIds,
    showError,
    includeDeleted,
  ]);

  const setSearchQuery = useCallback(
    (value: string) => {
      setClientOptionsFilter({ name: value, pageNumber: 1 });
    },
    [setClientOptionsFilter],
  );

  const setClientOptionsTeamIds = useCallback(
    (value: number[]) => {
      setClientOptionsFilter({ teamIds: value, pageNumber: 1 });
    },
    [setClientOptionsFilter],
  );

  const loadMore = () => {
    if (needLoadMore) {
      setClientOptionsFilter({ pageNumber: pageNumber + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const refresh = useCallback(() => {
    setClientOptionsFilter({ pageNumber: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setClientOptionsFilter, includeDeleted]); // we need reset list when includeDeleted property changes

  useEffect(() => {
    fetchClientOptions();

    return () => {
      prevRequestPayload = null;
    };
  }, [fetchClientOptions]);

  return {
    loading,
    items: [...currentClient, ...items],
    searchQuery: filters.name || '',
    teamIds: filters.teamIds,
    setSearchQuery,
    setClientOptionsTeamIds,
    setCurrentClientOption,
    loadMore: needLoadMore ? loadMore : undefined,
    refresh,
  };
};

export default useClientOptions;

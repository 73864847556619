import { action } from 'easy-peasy';

import { IActionSetTeamMembersLocationOffline } from '../types';

const setTeamMembersLocationOffline: IActionSetTeamMembersLocationOffline = action(
  (state, payload) => {
    state.teamMembersLocation.items = state.teamMembersLocation.items.map(item =>
      item.user.id === payload ? { ...item, dateTime: null } : item,
    );
  },
);

export default setTeamMembersLocationOffline;

import {
  IClientNamesRequest,
  IClientOption,
  IClientOptionsRequestPayload,
  IClientsByPolygonRequestPayload,
  IGetClientRequestPayload,
} from '~/store/client/types';
import { IClientCommonRequestParams } from '../clientDetails/types';
import { privateAxios } from '../requestsCollection';
import { IParams, IResponse } from '../types';
import { IClient, IClientDetailed, IClientRequest, IEditRiskRequest } from './types';
import { IGetAllocatedClientsRequestPayload } from '~/store/network/types';
import { IClientAllocationDetailed } from '../clientAllocation/types';

export const getClients = (
  clinicId: string,
  teamIds: string[],
  params: IParams,
): IResponse<{ items: IClient[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/list?${
      teamIds ? teamIds.map(item => `&teamIds=${item}`).join('') : ''
    }`,
    params,
  });

export const getClientsOptions = ({
  clinicId,
  ...params
}: IClientOptionsRequestPayload): IResponse<{ items: IClientOption[]; total: number }> =>
  privateAxios(
    {
      method: 'get',
      url: `clinic/${clinicId}/client/list-by-teams`,
      params,
    },
    'v2',
  );

export const getClientsByPolygon = ({
  clinicId,
  ...data
}: IClientsByPolygonRequestPayload): IResponse<IClientDetailed[]> =>
  privateAxios(
    {
      method: 'post',
      url: `clinic/${clinicId}/client/list-by-polygon`,
      data,
    },
    'v2',
  );

export const getTeamClients = (
  clinicId: string,
  teamId: string,
  params: IParams,
): IResponse<{ items: IClient[]; total: number }> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client/list`,
    params,
  });

export const getAvailableClientsByTeams = (
  clinicId: string,
  teamIds: number[],
): IResponse<IClientDetailed[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/client/list-by-teams?${teamIds
      .map(item => `&teamIds=${item}`)
      .join('')}`,
  });

export const getAllocatedClients = ({
  clinicId,
  teamId,
  ...params
}: IGetAllocatedClientsRequestPayload): IResponse<IClientAllocationDetailed[]> =>
  privateAxios({
    method: 'get',
    url: `clinic/${clinicId}/act-team/${teamId}/client-allocation/network`,
    params,
  });

export const getClient = (
  clinicId: string,
  teamId: string,
  clientId: string,
): IResponse<IClientDetailed> =>
  privateAxios(
    {
      method: 'get',
      url: `/clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
    },
    'v2',
  );

export const archiveClient = (
  { clinicId, teamId, clientId }: IClientCommonRequestParams,
  data: { dischargeReasonId: number; dischargeDate: string },
): IResponse<void> =>
  privateAxios({
    method: 'delete',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
    data,
  });

export const restoreClient = ({
  clinicId,
  teamId,
  clientId,
}: IClientCommonRequestParams): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/recover`,
  });

export const addClient = (
  { clinicId, teamId }: { clinicId: string; teamId: string },
  data: IClientRequest,
): IResponse<IClientDetailed> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client`,
    data,
  });

export const editClient = (
  { clinicId, teamId, clientId }: IClientCommonRequestParams,
  data: IClientRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}`,
    data,
  });

export const uploadClientPhoto = (
  { clinicId, teamId, clientId }: IClientCommonRequestParams,
  photo: string,
): IResponse<void> =>
  privateAxios({
    method: 'post',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/photo`,
    data: { photo },
  });

export const editSuicideRisk = (
  { clinicId, teamId, clientId }: IClientCommonRequestParams,
  data: IEditRiskRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/risks/suicide-risk`,
    data,
  });

export const editRiskAssessment = (
  { clinicId, teamId, clientId }: IClientCommonRequestParams,
  data: IEditRiskRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/risks/risk-assessment`,
    data,
  });

/** CLIENT PREFERRED NAME AND PERSONAL PRONOUNS */

export const updateClientNames = (
  { clinicId, teamId, clientId }: IGetClientRequestPayload,
  data: IClientNamesRequest,
): IResponse<void> =>
  privateAxios({
    method: 'put',
    url: `clinic/${clinicId}/act-team/${teamId}/client/${clientId}/names`,
    data,
  });

export default {};

import { ReactElement, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';

import { extractErrorMessage } from '~/utils/error/error';

import Loader from '~/ui/components/common/Loader';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';
import Form from '../../reusable/ClientAllocationForm';
import { NETWORK } from '~/ui/constants/paths';
import extractFullName from '~/utils/text/extractFullName';

const AddClientAllocationGU = (): ReactElement => {
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(false);

  const {
    clinic: userClinic,
    id: userId,
    photo,
    ...user
  } = useStoreState(state => state.user.current);
  const locationGroups = useStoreState(state => state.clientAllocation.locationGroups);

  const { showError } = useStoreActions(actions => actions.snackbar);
  const { onGetActTeamsAvailable } = useStoreActions(action => action.actTeam);
  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetAllocationClientsByGroupGU = useStoreActions(
    actions => actions.clientAllocation.onGetAllocationClientsByGroupGU,
  );

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([
        onGetMyClinic(),
        onGetActTeamsAvailable({ clinicId: String(userClinic.id) }),
        onGetAllocationClientsByGroupGU({ clinicId: String(userClinic.id) }),
      ]);
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    onGetActTeamsAvailable,
    onGetMyClinic,
    showError,
    userClinic.id,
    onGetAllocationClientsByGroupGU,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  if (loading) return <Loader />;

  const breadcrumbItems = [
    { to: NETWORK, title: `${userClinic.name} Teams` },
    { to: pathname, title: 'Add Client Allocation' },
  ];

  const availableTeamMembers = [
    { id: userId, fullName: extractFullName(user), photo, isDeleted: false },
  ];

  return (
    <div>
      <h2>Add Client Allocation</h2>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbItems} />
      <Form
        teamMembers={availableTeamMembers}
        clients={[]}
        locationGroups={locationGroups}
        clinicId={String(userClinic.id)}
      />
    </div>
  );
};

export default AddClientAllocationGU;

import { ReactElement, useState } from 'react';
import classNames from 'classnames';

import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { ICustomDictionaryType } from '~/services/api/dictionaries/types';

import styles from '../../../Dictionaries.module.scss';

interface IProps extends ICustomDictionaryType {
  setDictionaryType: (dictionaryType: ICustomDictionaryType) => void;
  selectedDictionaryType: string;
}

const DictionariesListItem = ({
  type,
  name,
  setDictionaryType,
  selectedDictionaryType,
  ...rest
}: IProps): ReactElement => {
  const [isHovered, setHovered] = useState<boolean | null>(null);

  return (
    <div
      role="presentation"
      key={type}
      className={classNames(styles.item, {
        [styles.itemActive]: type === selectedDictionaryType,
      })}
      onClick={() => setDictionaryType({ type, name, ...rest })}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(null)}
    >
      {name}
      <IconButton disabled>
        <ArrowForwardIosIcon
          fontSize="small"
          color={isHovered || type === selectedDictionaryType ? 'primary' : 'inherit'}
        />
      </IconButton>
    </div>
  );
};

export default DictionariesListItem;

import { ReactElement, ReactNode } from 'react';
import InputMask from 'react-input-mask';
import { Controller, FieldErrors, Control } from 'react-hook-form';
import get from 'lodash/get';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import styles from './InputMask.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label: string;
  placeholder?: string;
  mask?: string;
  alwaysShowMask?: boolean;
  tabIndex?: number;
  endAdornment?: ReactNode;
  maskPlaceholder?: string;
}

const InputMaskComponent = ({
  control,
  name,
  label,
  errors,
  placeholder,
  mask = '999-999-9999',
  alwaysShowMask,
  tabIndex,
  endAdornment,
  maskPlaceholder,
}: IProps): ReactElement => {
  const errorText = get(errors, `${name}.message`, '');
  const isError = Boolean(errorText);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onBlur, onChange } }) => (
        <InputMask
          mask={mask}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          alwaysShowMask={alwaysShowMask}
          maskChar={maskPlaceholder}
        >
          {
            ((inputProps: any) => (
              <FormControl variant="outlined" size="small" className={styles.inputContainer}>
                {label && (
                  <InputLabel shrink error={isError}>
                    {label}
                  </InputLabel>
                )}
                <OutlinedInput
                  inputProps={{ ...inputProps, tabIndex }}
                  notched={!!label}
                  label={label}
                  type="tel"
                  className="input"
                  placeholder={placeholder}
                  error={isError}
                  endAdornment={endAdornment}
                />
                {isError && <FormHelperText error>{errorText.toString()}</FormHelperText>}
              </FormControl>
            )) as unknown as ReactNode
          }
        </InputMask>
      )}
    />
  );
};

export default InputMaskComponent;

import setUser from './setUser';
import setRole from './setRole';
import resetUser from './resetUser';
import setAuthorized from './setAuthorized';
import setAvatar from './setAvatar';
import removeAvatar from './removeAvatar';
import resetReportsStore from '../../reports/actions/resetReportsStore';
import resetResourceStore from '../../resource/actions/resetResourceStore';
import resetSystemAlertsStore from '../../systemAlert/actions/resetSystemAlertsStore';
import resetClientStore from '../../client/actions/resetClientStore';
import resetCoordinateStore from '../../coordinate/actions/resetCoordinateStore';
import resetActTeamStore from '../../actTeam/actions/resetActTeamStore';
import resetNetworkStore from '../../network/actions/resetNetworkStore';

export default {
  resetUser,
  setAuthorized,
  setUser,
  setRole,
  setAvatar,
  removeAvatar,
  resetReportsStore,
  resetResourceStore,
  resetClientStore,
  resetSystemAlertsStore,
  resetCoordinateStore,
  resetActTeamStore,
  resetNetworkStore,
};

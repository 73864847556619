import initStore from './initStore';
import thunks from './thunks';
import actions from './actions';

import { INetworkModel } from './types';

const network: INetworkModel = {
  ...initStore,
  ...actions,
  ...thunks,
};

export default network;

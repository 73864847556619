import { ReactElement } from 'react';
import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { FormControlProps } from '@material-ui/core/FormControl';
import get from 'lodash/get';
import TextField from '@material-ui/core/TextField';

import styles from './Textarea.module.scss';

interface IProps {
  register: UseFormRegister<any>;
  errors: FieldErrors;
  rows?: number;
  size?: FormControlProps['size'];
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  tabIndex?: number;
}

const Textarea = ({
  register,
  errors,
  rows = 4,
  size = 'medium',
  name,
  label = '',
  disabled = false,
  placeholder = '',
  tabIndex,
}: IProps): ReactElement => {
  const { ref, ...registerProps } = register(name);

  const error = get(errors, `${name}.message`, '');

  return (
    <TextField
      inputProps={{
        tabIndex,
      }}
      {...registerProps}
      inputRef={ref}
      variant="outlined"
      multiline
      minRows={rows}
      size={size}
      className={styles.textarea}
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      InputLabelProps={{ shrink: true }}
      error={Boolean(error)}
      helperText={error.toString()}
    />
  );
};

export default Textarea;

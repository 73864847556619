import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { INetworkActions, IThunkGetTeamMembersLocation } from '../types';

const onGetTeamMembersLocation: IThunkGetTeamMembersLocation = thunk(
  async ({ setTeamMembersLocation }: Actions<INetworkActions>) => {
    const { data } = await api.network.getTeamMembersLocation();

    setTeamMembersLocation(data);
  },
);

export default onGetTeamMembersLocation;

import { action } from 'easy-peasy';
import { IActionSetClientDetails } from '../types';

const setClientDetails: IActionSetClientDetails = action((state, payload) => {
  const {
    deEscalationTechniques,
    diagnoses,
    medications,
    triggers,
    supportContacts,
    allergies,
    addresses,
    phones,
    treatmentPlans,
    memo,
    status,
  } = payload;

  if (deEscalationTechniques) {
    state.deEscalationTechniques = deEscalationTechniques;
  }

  if (diagnoses) {
    state.diagnoses = diagnoses;
  }

  if (allergies) {
    state.allergies = allergies;
  }

  if (medications) {
    state.medications = medications.sort((a, b) =>
      a.medication.name.toLowerCase() < b.medication.name.toLowerCase() ? -1 : 1,
    );
  }

  if (triggers) {
    state.triggers = triggers;
  }
  if (supportContacts) {
    state.supportContacts = supportContacts;
  }
  if (addresses) {
    state.addresses = addresses.sort(a => (a.isPrimary ? -1 : 1));
  }
  if (phones) {
    state.phones = phones.sort(a => (a.isPrimary ? -1 : 1));
  }
  if (treatmentPlans) {
    state.treatmentPlans = treatmentPlans;
  }

  if (typeof memo !== 'undefined') {
    state.memo = memo;
  }
  if (status) {
    state.status = status;
  }
});

export default setClientDetails;

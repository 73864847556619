import { useState, ReactElement } from 'react';
import { Controller, Control, FieldErrors } from 'react-hook-form';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import AvatarUpload from '~/ui/components/common/AvatarUpload';
import { isFile } from '~/utils/file';
import PrivateImage from '../../common/PrivateImage';

import styles from './AvatarUpload.module.scss';

interface IProps {
  control: Control<any>;
  errors: FieldErrors;
  name: string;
  label?: string;
  isPhoto?: boolean;
}

const AvatarUploadComponent = ({
  control,
  isPhoto,
  errors,
  name,
  label = '',
}: IProps): ReactElement => {
  const [uploadError, setUploadError] = useState('');
  const error = get(errors, `${name}.message`, '');

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <FormControl component="fieldset" className={styles.uploadControl} fullWidth>
          <FormLabel component="legend" className={styles.label}>
            {label}
          </FormLabel>
          <AvatarUpload
            contentTitle={isPhoto ? 'Photo' : 'Logo'}
            value={value}
            onUpload={file => {
              onChange(file);
              setUploadError('');
            }}
            filePreview={
              <div className={styles.imagePreview}>
                <PrivateImage
                  src={isFile(value) ? URL.createObjectURL(value) : value}
                  className={styles.image}
                  alt="Preview"
                />
              </div>
            }
            onRemove={() => onChange('')}
            onError={err => setUploadError(err)}
          />
          {uploadError && <FormHelperText error>{uploadError}</FormHelperText>}
          {error && <FormHelperText error>{error.toString()}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default AvatarUploadComponent;

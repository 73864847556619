import { getNetworkFilter } from '~/services/localStorage/filters';
import { INetworkStore } from './types';

const initStore: INetworkStore = {
  filters: getNetworkFilter() || {
    clientIds: [],
    teamIds: [],
    showList: false,
    name: '',
  },
  selectedMarkerId: null,
  teamMembersLocation: { items: [] },
  allocatedClients: [],
  activeTab: 0,
};

export default initStore;

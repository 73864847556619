import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import unionBy from 'lodash/unionBy';

import Form from '../../../Clinics/ViewClinic/LocalAdmin/Form';
import Loader from '~/ui/components/common/Loader';
import Breadcrumbs from '~/ui/components/common/Breadcrumbs';

import api from '~/services/api';
import { useStoreActions, useStoreState } from '~/store/hooks';
import { formatDefaultValues } from '~/utils/formatDefaultValues';
import { extractErrorMessage } from '~/utils/error/error';
import { isFile } from '~/utils/file';
import removeActPrefix from '~/utils/text/removeActPrefix';
import compareRoles from '~/utils/compareRoles';

import { ACT_TEAM_MANAGEMENT, VIEW_ACT_TEAM } from '~/ui/constants/paths';
import { IUserRole } from '~/types';
import { IActTeamMember } from '~/services/api/actTeamMember/types';
import { apiMemberTypes, MemberType } from '~/ui/constants/memberTypes';

import styles from './EditTeamMember.module.scss';

const EditTeamMember = (): ReactElement => {
  const { teamMemberId, actTeamId } = useParams<{ teamMemberId: string; actTeamId: string }>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const { clinic: userClinic } = useStoreState(state => state.user.current);
  const clinic = useStoreState(state => state.clinic.current);
  const teamMember = useStoreState(state => state.actTeamMember.current);
  const actTeam = useStoreState(state => state.actTeam.current);

  const onGetMyClinic = useStoreActions(actions => actions.clinic.onGetMyClinic);
  const onGetActTeamMember = useStoreActions(actions => actions.actTeamMember.onGetActTeamMember);
  const onGetActTeam = useStoreActions(actions => actions.actTeam.onGetClinicActTeam);
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const currentClinicRoles = (teamMember as IActTeamMember)?.roles?.filter(
    role => role.clinic.id === Number(userClinic.id),
  );

  // show assigned teams only from current clinic
  const filteredTeams = unionBy(
    (currentClinicRoles || [])?.flatMap(role => role.teams),
    'id',
  );

  const isGlobalUser = currentClinicRoles?.find(role => role.name === IUserRole.GlobalUser);

  const onMount = useCallback(async () => {
    try {
      setLoading(true);

      await Promise.all([
        onGetMyClinic(),
        onGetActTeamMember({ teamMemberId, actTeamId, clinicId: String(userClinic.id) }),
        onGetActTeam({ clinicId: String(userClinic.id), actTeamId }),
      ]);
      setLoading(false);
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  }, [
    actTeamId,
    onGetActTeam,
    onGetActTeamMember,
    onGetMyClinic,
    showError,
    teamMemberId,
    userClinic.id,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const isPending = teamMember?.status === 'Pending';

  const onSubmit = async (vals: any) => {
    try {
      const { email, emailDomain, primaryRole, ...restVals } = vals;
      const payload = isPending ? { email: email + emailDomain, ...restVals } : { ...vals };
      setIsLoadingSubmit(true);

      if (isFile(payload.photo)) {
        const formData = new FormData();
        formData.append('file', payload.photo);
        const { fileName } = await api.file.uploadFile(formData).then(r => r.data);
        payload.photo = fileName;
      } else {
        const photoId = payload.photo?.split('/').slice(-1).pop();
        payload.photo = photoId;
      }

      // comparing to user role combination we need to use different api endpoint and payload
      const isToGlobalUser = compareRoles(primaryRole, MemberType.GLOBAL_USER);
      const isToTeamMember =
        compareRoles(primaryRole, MemberType.TEAM_MEMBER) ||
        compareRoles(primaryRole, MemberType.TEAM_LEADER);
      const isRoleUpdate = !compareRoles(
        removeActPrefix((teamMember as IActTeamMember).primaryRole?.name),
        primaryRole,
      );

      if (isRoleUpdate) {
        if (isToGlobalUser) {
          await api.actTeamMember.updateToGlobalUser(
            String(userClinic.id),
            actTeamId,
            teamMemberId,
            {
              ...payload,
              updateToGlobalUser: isToGlobalUser,
            },
          );
        } else if (isToTeamMember) {
          await api.actTeamMember.updateToTeamMember(userClinic.id, Number(teamMemberId), {
            ...payload,
            roleToSet: apiMemberTypes[primaryRole],
            teamId: Number(actTeamId),
          });
        }
      } else if (isGlobalUser) {
        await api.actTeamMember.updateGlobalUser(userClinic.id, Number(teamMemberId), payload);
      } else {
        await api.actTeamMember.updateTeamMember(
          String(userClinic.id),
          actTeamId,
          teamMemberId,
          payload,
        );
      }

      showNotify({ message: 'Member successfully updated.' });

      navigate(VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), { state: { selectedTab: 0 } });
    } catch (e) {
      setIsLoadingSubmit(false);
      showError(extractErrorMessage(e));
    }
  };

  if (loading || !teamMember || !actTeam || !clinic) return <Loader />;

  const emailDomainOptions = clinic.emailDomains.map(emailDomain => ({
    value: emailDomain,
    label: emailDomain,
  }));

  const breadcrumbsItems = [
    {
      title: `${userClinic.name} Teams`,
      to: ACT_TEAM_MANAGEMENT,
    },
    {
      title: actTeam.name,
      to: { pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId), state: { selectedTab: 0 } },
    },
    {
      title: 'Edit Team Member Profile',
      to: pathname,
    },
  ];

  const leavePageDescription = `Are you sure you want to cancel editting ${teamMember.firstName} ${teamMember.lastName} profile?   All the changes will be discarded`;
  const cancelLinkTo = {
    pathname: VIEW_ACT_TEAM.replace(':actTeamId', actTeamId),
    state: { selectedTab: 0 },
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Edit Team Member Profile</h2>
      </div>
      <Breadcrumbs itemsForBreadcrumbs={breadcrumbsItems} />
      <Form
        defaultValues={formatDefaultValues({ ...teamMember, teams: filteredTeams })}
        isEdit
        submitText="Save Changes"
        isProcessing={isLoadingSubmit}
        cancelLinkTo={cancelLinkTo}
        leavePageDescription={leavePageDescription}
        onSubmit={onSubmit}
        isPending={isPending}
        emailDomainOptions={isPending ? emailDomainOptions : undefined}
      />
    </div>
  );
};

export default EditTeamMember;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IClientActions, IClientStore, IThunkGetClientsMapList } from '../types';
import unionBy from 'lodash/unionBy';
import { sortByFullName } from '~/ui/pages/Reports/helpers/sortByName';

const onGetClientsMapList: IThunkGetClientsMapList = thunk(
  async ({ setClients }: Actions<IClientActions>, payload, { getState }) => {
    const state = getState() as IClientStore;
    const { clinicId, teamId, teamIds, params } = payload;
    let data;

    if (teamId) {
      data = await api.client.getTeamClients(clinicId, teamId, params).then(r => r.data);
    } else {
      data = await api.client.getClients(clinicId, teamIds, params).then(r => r.data);
    }

    const mergedItems = (unionBy([...data.items, ...state.list], 'id') || []).sort(sortByFullName);

    setClients({
      items: params.pageNumber === 1 ? data.items : mergedItems,
      pagination: { ...params, total: data.total },
    });
  },
);

export default onGetClientsMapList;

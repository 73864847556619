import { privateAxios } from '../requestsCollection';
import { IResponse } from '../types';
import { ITeamMemberLocation } from './types';

export const locationTrackerBaseUrl = process.env.REACT_APP_LOCATION_API_URL;

export const getTeamMembersLocation = (): IResponse<ITeamMemberLocation[]> =>
  privateAxios({
    method: 'get',
    url: `location/team-members`,
    baseURL: locationTrackerBaseUrl,
  });

import { ReactElement, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import EmailForm from './components/EmailForm';
import CodeForm from './components/CodeForm';
import PasswordForm from './components/PasswordForm';
import api from '~/services/api';
import { extractErrorMessage } from '~/utils/error/error';

import SignUpStep from './enums/signUpStep';
import { SIGN_IN } from '~/ui/constants/paths';

import companyLogo from '~/ui/assets/images/logo.svg';
import styles from './SignUp.module.scss';

const SignUp = (): ReactElement => {
  const navigate = useNavigate();
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentCode, setCurrentCode] = useState('');
  const [error, setError] = useState<string>(null);
  const [step, setStep] = useState(SignUpStep.zero);

  const onSubmitEmail = async (vals: { email: string }) => {
    try {
      const email = vals.email?.trim();
      await api.auth.validateEmail({ email: email.trim() });
      setCurrentEmail(email);
      setStep(SignUpStep.one);
      setError(null);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const onSubmitCode = async (vals: any) => {
    const codeValue = Object.values(vals).join('');
    try {
      await api.auth.validateCode({ email: currentEmail.trim(), code: codeValue });
      setCurrentCode(codeValue);
      setStep(SignUpStep.two);
      setError(null);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const onSubmitPassword = async (vals: any) => {
    try {
      const payload = { email: currentEmail, registrationCode: currentCode, ...vals };
      await api.auth.signUp(payload);
      navigate(SIGN_IN);
    } catch (e) {
      setError(extractErrorMessage(e));
    }
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  return (
    <div className={styles.container}>
      <img src={companyLogo} className={styles.logo} alt="Logo" />
      {step === SignUpStep.zero && (
        <EmailForm onSubmit={onSubmitEmail} error={error} resetError={resetError} />
      )}
      {step === SignUpStep.one && (
        <CodeForm
          onSubmit={onSubmitCode}
          error={error}
          subTitle="Enter the code from the registration email to verify your email"
        />
      )}
      {step === SignUpStep.two && (
        <PasswordForm
          title="Welcome to VelloHealth"
          subTitle="Please set up your password"
          buttonText="Sign Up"
          onSubmit={onSubmitPassword}
          error={error}
        />
      )}
    </div>
  );
};

export default SignUp;

import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { FRESH_DESK_SUPPORT } from '~/ui/constants/paths';
import image from '~/ui/assets/images/404.png';
import styles from './ErrorPage.module.scss';

const ErrorPage = (): ReactElement => {
  const navigate = useNavigate();

  const openSupportLink = () => {
    window.open(FRESH_DESK_SUPPORT, '_blank');
  };

  return (
    <div className={styles.accessDenied}>
      <p className={styles.title}>Oops! Something went wrong.</p>
      <p className={styles.subTitle}>
        Looks like something went wrong on our end. Please try again, and let us know if it keeps
        happening!
      </p>
      <img src={image} alt="404" />
      <Box className={styles.buttons}>
        <Button type="button" color="primary" variant="contained" onClick={openSupportLink}>
          Submit a Ticket
        </Button>
        <Button color="primary" variant="outlined" size="large" onClick={() => navigate('/')}>
          Back To Home
        </Button>
      </Box>
    </div>
  );
};
export default ErrorPage;

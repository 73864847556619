import { isPast } from 'date-fns';
import classNames from 'classnames';

import Chip from '~/ui/components/common/Chip';

import { setNoon } from '~/utils/date/date';

import { IHealthDetailItem } from '../types';
import chipStyles from '~/ui/components/common/Chip/Chip.module.scss';

const renderCustomContent = (item: IHealthDetailItem): JSX.Element => (
  <>
    {!!item.status?.name && (
      <Chip
        text={item.status?.name}
        archived={item.isArchived}
        className={classNames(chipStyles.outlineLabel, chipStyles.outlineLabelBlack)}
      />
    )}
    {!!item.severityLevel?.name && (
      <Chip
        text={item.severityLevel?.name}
        archived={item.isArchived}
        className={chipStyles.outlineLabel}
      />
    )}
    {item.endDate && isPast(new Date(setNoon(item.endDate))) ? (
      <Chip text="EXPIRED" className={chipStyles.outlineLabel} />
    ) : undefined}
  </>
);

export default renderCustomContent;

import { Actions, thunk } from 'easy-peasy';

import api from '~/services/api';

import { IClientActions, IThunkGetClientsByPolygon } from '../types';

const onGetClientsByPolygon: IThunkGetClientsByPolygon = thunk(
  async ({ setAvailableClients }: Actions<IClientActions>, payload) => {
    const data = await api.client.getClientsByPolygon(payload).then(r => r.data);

    setAvailableClients(data);
  },
);

export default onGetClientsByPolygon;

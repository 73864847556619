import AddIcon from '@material-ui/icons/Add';
import { ReactElement, Dispatch, SetStateAction } from 'react';

import Button from '~/ui/components/common/Button';
import ConfirmModal from '~/ui/components/common/ConfirmModal';
import DictionariesList from './components/DictionariesList/DictionariesList';
import ModalForm from './components/ModalForm';
import Table from './components/Table';

import { ICustomDictionaryItem, ICustomDictionaryType } from '~/services/api/dictionaries/types';
import DictionaryActionTYPE from './enums/dictionaryActionTypes';

import styles from './Dictionaries.module.scss';

interface IDictionariesProps {
  dictionaryType?: ICustomDictionaryType;
  defaultDictionaryType?: ICustomDictionaryType;
  dictionaryTypes: ICustomDictionaryType[];
  hasCustomFields: boolean;
  actionType: DictionaryActionTYPE | null;
  hasAlternativeValues: boolean;
  dictionaryItem: ICustomDictionaryItem | null;
  actionModalText: string;
  confirmText?: string;
  description?: string;
  clinicId?: number;
  setDictionaryType: (item: ICustomDictionaryType) => void;
  setDictionaryItem: Dispatch<SetStateAction<ICustomDictionaryItem | null>>;
  onAddDictionaryItem: () => void;
  onEditDictionaryItem: (item: ICustomDictionaryItem) => void;
  handleDictionaryItemUpdateOrAdd: (item: ICustomDictionaryItem) => void;
  onClose: () => void;
  onConfirmation: () => void;
}

const Dictionaries = ({
  dictionaryType,
  defaultDictionaryType,
  dictionaryTypes,
  hasCustomFields,
  actionType,
  dictionaryItem,
  hasAlternativeValues,
  actionModalText,
  confirmText,
  description,
  clinicId,
  onAddDictionaryItem,
  setDictionaryItem,
  setDictionaryType,
  onEditDictionaryItem,
  handleDictionaryItemUpdateOrAdd,
  onClose,
  onConfirmation,
}: IDictionariesProps): ReactElement => (
  <div>
    <div className={styles.header}>
      <h2>Dictionaries</h2>
      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => onAddDictionaryItem()}
      >
        Add {dictionaryType?.name}
      </Button>
    </div>
    <div className={styles.content}>
      {!defaultDictionaryType && (
        <DictionariesList
          dictionaryTypes={dictionaryTypes}
          selectedDictionaryType={dictionaryType.type}
          setDictionaryType={setDictionaryType}
        />
      )}

      {dictionaryType.type && (
        <div className={styles.tableContainer}>
          <Table
            dictionaryType={dictionaryType}
            hasCustomFields={hasCustomFields}
            setDictionaryItem={setDictionaryItem}
            onEditDictionaryItem={onEditDictionaryItem}
            clinicId={clinicId}
          />
        </div>
      )}

      {actionType && dictionaryItem && (
        <ModalForm
          hasCustomFields={hasCustomFields}
          hasAlternativeValues={hasAlternativeValues}
          dictionaryType={dictionaryType}
          dictionaryItem={dictionaryItem}
          handleDictionaryItemUpdateOrAdd={handleDictionaryItemUpdateOrAdd}
          onClose={onClose}
          actionModalText={actionModalText}
        />
      )}

      {!actionType && dictionaryItem && (
        <ConfirmModal
          confirmText={confirmText}
          description={description}
          onConfirm={() => {
            onConfirmation();
          }}
          onClose={() => onClose()}
        />
      )}
    </div>
  </div>
);

export default Dictionaries;

import { ReactElement, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStoreActions } from '~/store/hooks';
import { extractErrorMessage } from '~/utils/error/error';

import Dictionaries from './Dictionaries';

import { ICustomDictionaryItem, ICustomDictionaryType } from '~/services/api/dictionaries/types';
import DictionaryActionTYPE from './enums/dictionaryActionTypes';

const ClinicDictionaries = ({
  defaultDictionaryType,
}: {
  defaultDictionaryType?: ICustomDictionaryType;
}): ReactElement => {
  const { id: clinicId } = useParams<{ id: string }>();
  // dictionaryType -> selected dictionaryType, default value set in onMount function (first element of dictionariesTypes)
  // dictionaryItem -> selected dictionaryItem
  const [dictionaryType, setDictionaryType] =
    useState<ICustomDictionaryType>(defaultDictionaryType);
  const [dictionaryItem, setDictionaryItem] = useState<ICustomDictionaryItem | null>(null);

  const [actionType, setActionType] = useState<DictionaryActionTYPE | null>(null);

  const { onArchiveTypeItem, onRestoreTypeItem, onUpdateTypeItem, onAddTypeItem } = useStoreActions(
    actions => actions.dictionaries,
  );
  const { showError, showNotify } = useStoreActions(actions => actions.snackbar);

  const hasCustomFields = useMemo(() => !!dictionaryType.customFields, [dictionaryType]);

  const hasAlternativeValues = useMemo(() => !!dictionaryType.alternativeValues, [dictionaryType]);

  const handleDictionaryItemUpdateOrAdd = async (updatedItem: any) => {
    if (!updatedItem?.value) {
      showError('Value can not be blank');
      return;
    }
    if (
      hasCustomFields &&
      !updatedItem?.abbreviation &&
      dictionaryType.customFields.some(item => item.title === 'Abbreviation')
    ) {
      showError('Abbreviation can not be blank');
      return;
    }

    const method = actionType === DictionaryActionTYPE.add ? onAddTypeItem : onUpdateTypeItem;
    const word = actionType === DictionaryActionTYPE.add ? 'created' : 'updated';

    try {
      await method({ ...updatedItem, type: dictionaryType.type, clinicId });
      setActionType(null);
      setDictionaryItem(null);
      showNotify({ message: `Dictionary successfully ${word}` });
    } catch (e) {
      showError(extractErrorMessage(e));
    }
  };

  const onAddDictionaryItem = (): void => {
    setActionType(DictionaryActionTYPE.add);
    setDictionaryItem({} as ICustomDictionaryItem);
  };

  const onEditDictionaryItem = (item: any): void => {
    setActionType(DictionaryActionTYPE.edit);
    setDictionaryItem(item);
  };

  const onClose = (): void => {
    setActionType(null);
    setDictionaryItem(null);
  };

  const confirmText = dictionaryItem?.isArchived ? 'Restore' : 'Archive';
  const confirmMethod = dictionaryItem?.isArchived ? onRestoreTypeItem : onArchiveTypeItem;
  const description = dictionaryItem?.isArchived
    ? `Are you sure you want to restore the dictionary ${dictionaryItem?.value}?`
    : `Are you sure you want to archive the dictionary ${dictionaryItem?.value}? You will be able to restore it any time.`;

  const actionModalText = actionType === DictionaryActionTYPE.add ? 'Add Dictionary' : 'Save';

  const onConfirmation = (): void => {
    confirmMethod({
      type: dictionaryType?.type,
      id: dictionaryItem.id,
      clinicId: Number(clinicId),
    });
    setDictionaryItem(null);
  };

  return (
    <Dictionaries
      dictionaryType={dictionaryType}
      defaultDictionaryType={defaultDictionaryType}
      dictionaryTypes={[]}
      hasCustomFields={hasCustomFields}
      actionType={actionType}
      dictionaryItem={dictionaryItem}
      hasAlternativeValues={hasAlternativeValues}
      actionModalText={actionModalText}
      confirmText={confirmText}
      description={description}
      clinicId={Number(clinicId)}
      onAddDictionaryItem={onAddDictionaryItem}
      setDictionaryItem={setDictionaryItem}
      setDictionaryType={setDictionaryType}
      onEditDictionaryItem={onEditDictionaryItem}
      handleDictionaryItemUpdateOrAdd={handleDictionaryItemUpdateOrAdd}
      onClose={onClose}
      onConfirmation={onConfirmation}
    />
  );
};

export default ClinicDictionaries;

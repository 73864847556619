/* eslint no-underscore-dangle: 0 */
import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import {
  getAccessToken,
  getRefreshToken,
  getUserData,
  setTokens,
} from '~/services/localStorage/auth';
import ErrorCodes from '~/ui/pages/SignIn/models/ErrorCodes';
import forceLogout from '~/utils/auth/forceLogout';
import isAccessTokenValid from '~/utils/auth/isAccessTokenValid';
import refreshTokens from '~/utils/auth/refreshTokens';

const formatErrorResponse = (err: any) => err?.response;

type IRequestCb = (token: string) => void;

let isRefreshing = false;
const refreshSubscribers: { [key: string]: IRequestCb } = {};

// add request to the queue while refreshing access token
const subscribeTokenRefresh = (cb: IRequestCb, url?: string) => {
  if (url) {
    refreshSubscribers[url] = cb;
  }
};

// update token in the requests queue
const onRefreshed = (token: string) => {
  Object.values(refreshSubscribers).map(cb => cb(token));
};

const requestInterceptor = (instance: AxiosInstance, isPrivate: boolean) => {
  instance.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    if (!isPrivate) return config;

    let currentAccessToken = getAccessToken();
    const currentRefreshToken = getRefreshToken();

    const isTokenValid = isAccessTokenValid(currentAccessToken);

    if (!isTokenValid && !isRefreshing) {
      isRefreshing = true;
      const userData = getUserData();
      try {
        await refreshTokens(
          {
            accessToken: currentAccessToken,
            refreshToken: currentRefreshToken,
            ...userData,
          },
          ({ accessToken, refreshToken }) => {
            setTokens({ accessToken, refreshToken });
            currentAccessToken = accessToken;
          },
        );
      } finally {
        isRefreshing = false;
      }
    }

    if (currentAccessToken) {
      config.headers.Authorization = `Bearer ${currentAccessToken}`;
    }

    return config;
  });
};

const handleLogout = async (err: unknown) => {
  const errorCode = (err as unknown as { response: { data: { code: string } } })?.response?.data
    ?.code as ErrorCodes;
  if (errorCode !== ErrorCodes.REFRESH_TOKEN_USED) {
    await forceLogout();
  }
  window.location.reload();
};

const privateFlow = async (instance: AxiosInstance, error: AxiosError) => {
  const currentAccessToken = getAccessToken();
  const currentRefreshToken = getRefreshToken();
  const userData = getUserData();
  const originalConfig = error.config;

  if (error.response?.status === 403) {
    // logout if user deactivate or try to access restricted content with missing permissions
    handleLogout(error);
  }

  if (error.response?.status === 401 && !(originalConfig as any)._retry) {
    // add request to the queue while refreshing access token
    const retryOrigReq = new Promise(resolve => {
      subscribeTokenRefresh((newToken: string) => {
        // replace the expired token and retry
        if (originalConfig.headers) {
          originalConfig.headers.Authorization = `Bearer ${newToken}`;
        }
        resolve(instance(originalConfig));
      }, originalConfig.url);
    });

    // perform token refresh
    if (!isRefreshing && currentAccessToken && currentRefreshToken) {
      (originalConfig as any)._retry = true; // set retry option
      isRefreshing = true;
      try {
        await refreshTokens(
          {
            accessToken: currentAccessToken,
            refreshToken: currentRefreshToken,
            ...userData,
          },
          // Set new tokens
          // Request interceptor will get and use new updated `accessToken` from LocalStorage
          ({ accessToken, refreshToken }) => {
            setTokens({ accessToken, refreshToken });
            onRefreshed(accessToken);
            (originalConfig as any)._retry = false;
          },
        );
      } catch (err) {
        handleLogout(err);
      } finally {
        isRefreshing = false;
      }
    }

    return retryOrigReq;
  }

  const errorCode = (error as unknown as { data: { code: string } })?.data?.code as ErrorCodes;
  const shouldLogout =
    !currentAccessToken ||
    errorCode === ErrorCodes.NO_ROLE ||
    errorCode === ErrorCodes.REFRESH_TOKEN_INVALID;

  if (shouldLogout) {
    return forceLogout();
  }

  return Promise.reject(formatErrorResponse(error));
};

const responseInterceptor = (instance: AxiosInstance, isPrivate: boolean) => {
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (!isPrivate) {
        return Promise.reject(formatErrorResponse(error));
      }

      return privateFlow(instance, error);
    },
  );
};

const addInterceptors = (instance: AxiosInstance, isPrivate: boolean): void => {
  requestInterceptor(instance, isPrivate);
  responseInterceptor(instance, isPrivate);
};

const axiosInstance = (baseURL: string, isPrivate = false): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: {
      indexes: null,
    },
  });
  addInterceptors(instance, isPrivate);
  return instance;
};

export default axiosInstance;
